var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',[_c('v-card-title',{staticClass:"grid-close"},[_c('span',{staticClass:"headline"},[_vm._v(" Opciones para "+_vm._s(_vm.item.name)+" ")]),_c('v-btn',{staticStyle:{"right":"10px","top":"10px"},attrs:{"icon":"","color":"primary","fab":"","absolute":""},on:{"click":function($event){return _vm.$emit('cancel')}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v("fa-times")])],1)],1),_c('v-divider'),_c('v-card-text',[_c('v-overlay',{staticClass:"loading-center",attrs:{"value":_vm.saving}},[_c('lottie',{attrs:{"options":_vm.defaultOptions,"height":300,"width":400},on:{"animCreated":_vm.handleAnimation}})],1),_c('v-row',[(_vm.loading)?_c('v-col',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block mt-5",attrs:{"type":"image"}})],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-skeleton-loader',{ref:"skeleton",staticClass:"mx-auto d-none d-md-block mt-5",attrs:{"type":"image"}})],1)],1)],1):_c('v-col',{attrs:{"cols":"12"}},[_c('a-transfer',{attrs:{"listStyle":{
              height: '400px!important',
              width: '45%',
              background: 'white',
            },"data-source":_vm.categories,"titles":['Todas las opciones', 'Opciones vinculadas'],"target-keys":_vm.optionsSelected,"selected-keys":_vm.selectedKeys,"show-search":true,"render":(item) => item.title,"filter-option":_vm.filterOption,"locale":{
              itemUnit: '',
              itemsUnit: '',
              notFoundContent: 'Lista vacía',
              searchPlaceholder: 'Buscar sección',
            }},on:{"search":_vm.handleSearch,"change":_vm.handleChange,"selectChange":_vm.handleSelectChange}})],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{staticClass:"mt-3 save-btn mr-5",attrs:{"color":"primary","dark":""},on:{"click":_vm.updateOptions}},[_vm._v(" Guardar ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }