<template>
  <v-container fluid>
    <v-snackbar top :timeout="3000" v-model="snackbar">
      {{ snackbarText }}
    </v-snackbar>
    <v-overlay class="loading-center" :value="saving">
      <lottie
        :options="defaultOptions"
   :height="150"
        :width="150"
        v-on:animCreated="handleAnimation"
      />
    </v-overlay>

    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <h1>
          Métodos de pago
        </h1>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCountry"
          :items="countries"
          placeholder="Escoge el pais"
          outlined
          item-text="name"
          item-value=".key"
          rounded
          class="mx-4"
        />
      </v-col>

      <v-col cols="12" md="6">
        <v-select
          v-model="selectedCity"
          :items="cities"
          placeholder="Escoge la ciudad"
          outlined
          :disabled="!selectedCountry"
          item-text="name"
          item-value=".key"
          rounded
          class="mx-4"
        />
      </v-col>
    </v-row>
    <v-row class="pa-0 ma-0">
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="paymentGateways"
          :items-per-page="10"
          :loading="loading"
          :sort-desc="true"
          item-key=".key"
          :single-expand="true"
          :footer-props="{
            itemsPerPageOptions: [10, 25, 50, 100, 250],
          }"
        >
          <template v-slot:loading>
            <div class="ma-5" style="position:relative;">
              <div class="inner-lottie">
                <lottie
                  :options="defaultOptions"
                  :height="300"
                  :width="400"
                  v-on:animCreated="handleAnimation"
                />
                <p
                  :class="
                    $vuetify.theme.dark
                      ? 'subtitle-1 primary--text'
                      : 'subtitle-1 black--text'
                  "
                >
                  Cargando Data
                </p>
              </div>
              <v-skeleton-loader
                ref="skeleton"
                type="table-tbody"
                class="mx-auto d-none d-md-block"
              ></v-skeleton-loader>
            </div>
          </template>

          <template v-slot:[`item.active`]="{ item }">
            <v-switch
              @change="
                switchControlChanged(
                  { active: item.active },
                  item['.key'],
                  item
                )
              "
              v-model="item.active"
            ></v-switch>
          </template>

          <template v-slot:[`item.categories`]="{ item }">
            {{ item.categories.length }}
          </template>

          <template v-slot:[`item.options`]="{ item }">
            <v-btn @click="editPaymentGateway(item)" small color="primary">
              Configurar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-dialog v-if="itemToEdit" v-model="modalEdit" max-width="800px">
      <edit-payment-gateway
        :city="selectedCity"
        @cancel="modalEdit = false"
        @success="handleSuccess"
        :key="randomKey"
        :item="itemToEdit"
      ></edit-payment-gateway>
    </v-dialog>
  </v-container>
</template>

<script>
import { db } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");
import editPaymentGateway from "./edit-payment-gateway";

export default {
  name: "payment-gateways",
  components: {
    lottie: Lottie,
    editPaymentGateway,
  },
  mixins: [lottieSettings],
  data() {
    return {
      loading: true,
      saving: false,
      snackbar: false,
      snackbarText: "",
      paymentGateways: [],
      modalEdit: false,
      cities: [],
      selectedCity: "",
      countries: [],
      selectedCountry: "",
      itemToEdit: null,
      randomKey: 0,
      headers: [
        {
          text: "Nombre",
          value: "displayName",
        },
        {
          text: "Categorias",
          value: "categories",
        },
        {
          text: "Activo",
          value: "active",
        },
        {
          value: "options",
          align: "end",
        },
      ],
    };
  },
  watch: {
    selectedCountry(countryId) {
      this.paymentGateways = [];
      this.selectedCity = "";
      this.$binding(
        "cities",
        db
          .collection("cities")
          .where("deleted", "==", false)
          .where("countryId", "==", countryId)
      );
    },
    selectedCity() {
      this.loading = true;
      this.$binding(
        "paymentGateways",
        db
          .collection("paymentGateways")
          .where("deleted", "==", false)
          .where("countryId", "==", this.selectedCountry)
      ).then(() => {
        this.loading = false;
      });
    },
  },
  methods: {
    handleSuccess() {
      this.snackbarText = "Información actualizada correctamente.";
      this.snackbar = true;
      this.modalEdit = false;
    },
    editPaymentGateway(item) {
      this.itemToEdit = item;
      this.randomKey = Math.random();
      this.modalEdit = true;
    },
    switchControlChanged(data, paymentGatewayId, paymentGateway) {
      if (data && paymentGatewayId) {
        this.saving = true;

        db.collection("paymentGateways")
          .doc(paymentGatewayId)
          .update({
            active: data.active,
          })
          .then(() => {
            this.saving = false;
            this.snackbarText = "Información actualizada correctamente.";
            this.snackbar = true;
          })
          .catch((err) => {
            this.saving = false;
            this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente.${err}`;
            this.snackbar = true;
            paymentGateway.active = !paymentGateway.active;
          });
      }
    },
  },
  mounted() {
    this.$store.state.visibleSearch = false;
    this.$binding(
      "countries",
      db.collection("countries").where("deleted", "==", false)
    ).then(() => {
      this.loading = false;
    });
  },
};
</script>

<style scoped>
.inner-lottie {
  position: absolute;
  z-index: 999;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
</style>
