<template>
  <v-card>
    <v-card-title class="grid-close">
      <span class="headline"> Opciones para {{ item.name }} </span>

      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        absolute
        @click="$emit('cancel')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text>
     
        <v-overlay class="loading-center" :value="saving">
          <lottie
            :options="defaultOptions"
            :height="300"
            :width="400"
            v-on:animCreated="handleAnimation"
          />
        </v-overlay>
        <v-row>
          <v-col v-if="loading">
            <v-row>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  ref="skeleton"
                  type="image"
                  class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="6">
                <v-skeleton-loader
                  ref="skeleton"
                  type="image"
                  class="mx-auto d-none d-md-block mt-5"
                ></v-skeleton-loader>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else cols="12">
            <a-transfer
              :listStyle="{
                height: '400px!important',
                width: '45%',
                background: 'white',
              }"
              :data-source="categories"
              :titles="['Todas las opciones', 'Opciones vinculadas']"
              :target-keys="optionsSelected"
              :selected-keys="selectedKeys"
              :show-search="true"
              @search="handleSearch"
              :render="(item) => item.title"
              :filter-option="filterOption"
              @change="handleChange"
              @selectChange="handleSelectChange"
              :locale="{
                itemUnit: '',
                itemsUnit: '',
                notFoundContent: 'Lista vacía',
                searchPlaceholder: 'Buscar sección',
              }"
            />
          </v-col>
        </v-row>
  
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn
        @click="updateOptions"
        color="primary"
        class="mt-3 save-btn mr-5"
        dark
      >
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import transfer from "ant-design-vue/lib/transfer";
import { db, fb } from "@/firebase";
import Lottie from "@/components/Lottie";
import lottieSettings from "@/mixins/lottieMixin";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");

export default {
  name: "add-remove-categories",
  props: ["item", "city"],
  components: {
    lottie: Lottie,
    "a-transfer": transfer,
  },
  mixins: [lottieSettings],
  data() {
    return {
      categories: [],
      selectedKeys: [],
      optionsSelected: [],
      loading: true,
      saving: false,
    };
  },
  methods: {
    handleChange(nextTargetKeys, direction, moveKeys) {
      this.optionsSelected = nextTargetKeys;
    },
    handleSearch(dir, value) {
      return value.toUpperCase();
    },
    handleSelectChange(sourceSelectedKeys, targetSelectedKeys) {
      this.selectedKeys = [...sourceSelectedKeys, ...targetSelectedKeys];
    },
    filterOption(inputValue, option) {
      return option.title.toUpperCase().indexOf(inputValue.toUpperCase()) > -1;
    },
    updateOptions() {
      this.saving = true;
      let data = {
        categories: this.optionsSelected,
        modifiedAt: new Date(),
        modifiedBy: this.$store.state.user[".key"],
      };
      db.collection("paymentGateways")
        .doc(this.item[".key"])
        .update(data)
        .then(() => {
          this.saving = false;
          this.$emit("success");
        })
        .catch((err) => {
          this.saving = false;
          this.snackbarText = `Ocurrió un error inesperado, inténtelo nuevamente`;
          this.snackbar = true;
        });
    },
  },
  async mounted() {
    this.$binding(
      "categories",
      db
        .collection("categories")
        .where("city", "==", this.city)
        .where("deleted", "==", false)
    ).then((categories) => {
      this.categories = categories.map((item) => {
        return {
          title: item.name ? item.name : "",
          key: item[".key"],
        };
      });
      this.loading = false;
    });
    this.optionsSelected = this.item.categories;
  },
};
</script>
